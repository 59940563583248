import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../i18n";
import bulgarianFlag from "../../static/images/bg.webp";

const ContactForm = ({ formFields }) => {
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [subject, setSubject] = useState("");
    const [categories, setCategories] = useState([]);
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [captchaAnswer, setCaptchaAnswer] = useState("");
    const [captchaError, setCaptchaError] = useState("");

    // Generate two random numbers for the captcha
    const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
    const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
    const [correctCaptcha, setCorrectCaptcha] = useState(0);

    useEffect(() => {
        // Generate two random numbers for captcha on component mount
        const randomNumber1 = Math.floor(Math.random() * 10); // Generates a number between 0 and 9
        const randomNumber2 = Math.floor(Math.random() * 10);
        setNum1(randomNumber1);
        setNum2(randomNumber2);
        setCorrectCaptcha(randomNumber1 + randomNumber2); // Set the correct answer for the captcha
    }, []); // Empty dependency array means this effect runs once on mount

    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^(\+359[1-9][0-9]{7,8})$/;
        return phoneRegex.test(phoneNumber);
    };

    // Fetch categories from WordPress REST API
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(
                    "https://panel.arros.website/wp-json/wp/v2/subject"
                ); // Adjust this URL
                const data = await response.json();
                if (Array.isArray(data)) {
                    // Check if data is an array
                    setCategories(data);
                } else {
                    //console.error("Received data is not an array:", data);
                    setCategories([]); // Reset to empty array if data is not as expected
                }
            } catch (error) {
                //console.error("Failed to fetch categories:", error);
                setCategories([]); // Handle error by setting categories to an empty array
            }
        };

        fetchCategories();
    }, []); // Empty dependency array means this effect runs once on mount

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate captcha
        if (parseInt(captchaAnswer) !== correctCaptcha) {
            setCaptchaError("Captcha is incorrect. Please try again."); // Set error message
            return; // Stop form submission if captcha is incorrect
        } else {
            setCaptchaError(""); // Clear error message if captcha is correct
        }

        // Validate phone
        const fullPhoneNumber = `+359${phone}`;
        if (!validatePhoneNumber(fullPhoneNumber)) {
            setPhoneError(
                "Phone number is invalid. Please enter a valid +359 phone number."
            );
            return; // Stop the form submission if phone validation fails
        }

        const formData = {
            firstName,
            lastName,
            email,
            phone,
            subject,
            message,
        };

        try {
            const response = await fetch(
                "https://panel.arros.website/wp-json/smarty/v1/submit-form/",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (response.ok) {
                setSuccessMessage(
                    "Thank you for contacting us! Your message has been received."
                ); // Set success message
                // Clear form fields
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setSubject("");
                setMessage("");
                setCaptchaAnswer("");

                // Regenerate captcha numbers
                const newNum1 = Math.floor(Math.random() * 10);
                const newNum2 = Math.floor(Math.random() * 10);
                setNum1(newNum1);
                setNum2(newNum2);
                setCorrectCaptcha(newNum1 + newNum2);
            } else {
                throw new Error("Network response was not ok.");
            }
        } catch (e) {
            //console.error("Submission Error", e);
            setSuccessMessage("Failed to submit. Please try again!");
        }
    };

    return (
        <div className="contact-form-container shadow-sm col-12 col-lg-8 mx-lg-auto mb-4 py-5 px-4 bg-white">
            {successMessage && (
                <div className="alert alert-success" role="alert">
                    <i className="bi bi-check-circle me-2"></i>
                    {t("successMsg")}
                </div>
            )}{" "}
            {/* Conditionally render success message */}
            <form className="contact-forms" onSubmit={handleSubmit}>
                <h3 className="text-center mb-5">{t("getInTouch")}</h3>
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="firstName" className="sr-only">
                            {t("firstName")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            value={firstName}
                            placeholder={t("firstName")}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="lastName" className="sr-only">
                            {t("lastName")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            value={lastName}
                            placeholder={t("lastName")}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="email" className="sr-only">
                            {t("email")}
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={email}
                            placeholder={t("email")}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div className="form-group">
                            <label htmlFor="phone" className="sr-only">
                                {t("phone")}
                            </label>
                            <div className="input-group">
                                <label
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <img
                                        src={bulgarianFlag}
                                        alt="Bulgarian flag"
                                        style={{ width: "25px" }}
                                    />{" "}
                                </label>

                                <input
                                    type="phone"
                                    className={`form-control ${
                                        phoneError ? "is-invalid" : ""
                                    }`}
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder={t("phone")}
                                    aria-label="Phone"
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                            {phoneError && (
                                <div className="text-danger mt-2">
                                    {t("phoneError")}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <label htmlFor="subject" className="sr-only">
                            {t("subject")}
                        </label>
                        <select
                            className="form-select"
                            id="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                        >
                            <option value="">{t("subject")}</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.slug}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-12 mb-3">
                        <label htmlFor="message" className="sr-only">
                            {t("message")}
                        </label>
                        <textarea
                            className="form-control"
                            id="message"
                            rows="3"
                            value={message}
                            placeholder={t("message")}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="captchaAnswer" className="form-label">
                            {t("captcha")} {num1} + {num2}?
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                captchaError ? "is-invalid" : ""
                            }`}
                            id="captchaAnswer"
                            value={captchaAnswer}
                            onChange={(e) => setCaptchaAnswer(e.target.value)}
                            required
                        />
                        {captchaError && (
                            <div className="text-danger mt-2">
                                {t("captchaError")}
                            </div>
                        )}
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-12">
                        <button
                            type="submit"
                            className="btn btn-primary py-3 w-100"
                        >
                            {t("submit")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
