import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ContactForm from "../components/ContactForm";
import { useTranslation } from "react-i18next";
import "../i18n";

const Page = ({ data, pageContext }) => {
    const { t } = useTranslation();
    const { isContactPage } = pageContext;
    const page = data.wpPage;
    const siteTitle = data.wp.generalSettings.title;
    const socialItems = data.allWpMenu.nodes[0]?.menuItems.nodes || [];
    const featuredImage = getImage(data.wpPage.featuredImage?.node?.localFile);
    const { contactForm } = data.wpPage;

    const getClassNameForSocialItem = (index) => {
        switch (index) {
            case 0: // First item
                return "bg-primary";
            case 1: // Second item
                return "bg-danger";
            default: // For third item onwards
                return "bg-secondary";
        }
    };

    return (
        <Layout>
            <Seo title={`${page.title} | ${siteTitle}`} />

            {/* Single page */}
            <section>
                <div className="header-intro theme-bg-primary shadow-sm py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h2 className="page-heading mb-0">
                                    {page.title}
                                </h2>
                            </div>
                            {!isContactPage && (
                                <div className="col-lg-4 col-md-12 d-none d-lg-block">
                                    <p className="text-muted text-right">
                                        <small>
                                            <b>{t("lastModified")}</b>
                                            {page.modifiedGmt} {t("hour")}
                                        </small>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {/* Page Content */}
            <section>
                <div className={!isContactPage && "single-post-wrapper"}>
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-12">
                                {(isContactPage && (
                                    <>
                                        {/* Content Page - Start */}
                                        <div className="contact-intro col-12 col-lg-8 mx-lg-auto mb-5 px-0 text-center">
                                            {featuredImage && (
                                                <GatsbyImage
                                                    className="d-inline-block mx-auto rounded-circle mb-3"
                                                    image={featuredImage}
                                                    alt={
                                                        data.wpPage
                                                            .featuredImage.node
                                                            .altText || "Logo"
                                                    }
                                                />
                                            )}
                                            <div className="speech-bubble bg-white p-4 p-lg-5 shadow-sm">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: page.content,
                                                    }}
                                                />
                                                <hr />
                                                <ul className="social-list-color list-inline mb-0">
                                                    {socialItems.map(
                                                        (item, index) => (
                                                            <li
                                                                key={index}
                                                                className="list-inline-item mb-3"
                                                            >
                                                                <Link
                                                                    className={getClassNameForSocialItem(
                                                                        index
                                                                    )}
                                                                    to={
                                                                        item.url
                                                                    }
                                                                >
                                                                    <i
                                                                        className={
                                                                            item
                                                                                .acfMenuIconClass
                                                                                .menuIcon
                                                                        }
                                                                    ></i>
                                                                </Link>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        <ContactForm formFields={contactForm} />
                                        {/* Content Page - End */}
                                    </>
                                )) || (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: page.content,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Page;

export const pageQuery = graphql`
    query ($databaseId: Int!) {
        wp {
            generalSettings {
                title
            }
        }
        wpPage(databaseId: { eq: $databaseId }) {
            title
            content
            modifiedGmt(formatString: "DD.MM.YYYY | HH:MM")
            uri
            author {
                node {
                    name
                }
            }
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 170
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, PNG]
                            )
                        }
                    }
                }
            }
        }
        allWpMenu(filter: { name: { eq: "Social Menu" } }) {
            nodes {
                menuItems {
                    nodes {
                        id
                        label
                        url
                        acfMenuIconClass {
                            menuIcon
                        }
                    }
                }
            }
        }
    }
`;
